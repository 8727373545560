import * as React from 'react';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageView from './views/PageView';
import ordering from './content/ordering.json'
import cryotherapy from './content/cryotherapy.json'
import travel from './content/travel.json'
import TravelRiskAssessmentView from './views/TravelRiskAssessmentView'
import vaccines from './content/vaccines.json'
import sales from './content/sales.json'
import earwaxremoval from './content/earwaxremoval.json'
import common_ailments from './content/common_ailments.json'
import about from './content/about.json'
import cookies from './content/cookies.json'
import repeats from './content/repeats.json'
import privacy_policy from './content/privacy_policy.json'
import findus from './content/findus.json'
import other_local_services from './content/other_local_services.json'
import home from './content/home.json'
import contact from './content/contact.json'

const history = createBrowserHistory();

const InsyncPharmacyView = () => {
    return (
        <Router history={history}>
            <Routes>
                <Route exact path='/' element={<PageView history={history} content={home}/>} />
                <Route path='/About' element={<PageView history={history} content={about}/>} />
                <Route path='/Cryotherapy' element={<PageView history={history} content={cryotherapy}/>} />
                <Route path='/InfluenzaVaccinations' element={<PageView history={history} content={vaccines} />} />
                <Route path='/PharmacySales' element={<PageView history={history} content={sales}/>} />
                <Route path='/OtherLocalServices' element={<PageView history={history} content={other_local_services} />} />
                <Route path='/Ordering' element={<PageView history={history} content={ordering}/>} />
                <Route path='/FindUs' element={<PageView history={history} content={findus}/>} />
                <Route path='/Travel' element={<PageView history={history} content={travel} />} />
                <Route path='/TravelRiskAssessment' element={<TravelRiskAssessmentView history={history} />} />
                <Route path='/Cookies' element={<PageView history={history} content={cookies}/>} />
                <Route path='/Contact' element={<PageView history={history} content={contact}/>} />
                <Route path='/PrivacyPolicy' element={<PageView history={history} content={privacy_policy}/>} />
                <Route path='/Repeats' element={<PageView history={history} content={repeats}/>} />
                <Route path='/EarWaxRemoval' element={<PageView history={history} content={earwaxremoval} />} />
                <Route path='/CommonAilments' element={<PageView history={history} content={common_ailments}/>} />
            </Routes>
        </Router>
    );
}

export default InsyncPharmacyView;