{
    "title": "page.cryotherapy.title",
    "components": [
        {
            "type": "panel",
            "title": "page.cryotherapy.whatis.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.cryotherapy.whatis.p1",
                    "key": "MC8Xxv0oQiy5At3yCB/UIw=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.whatis.p2",
                    "key": "61Wjt2i5QHiiBC2xTKvCgw=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.whatis.p3",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                }//,
                // {
                //     "type": "video",
                //     "url": "https://www.youtube.com/embed/H1mdJAc6-9w",
                //     "key": "s5hpsH+TSfO+V9fxjQnIWA=="
                // }
            ],
            "key": "whatis-panel"
        },
        {
            "type": "panel",
            "title": "page.cryotherapy.treatment.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.cryotherapy.treatment.subtitle",
                    "key": "mtD8bI5ISzGli7hwFzQUoQ=="
                },
                {
                    "type": "checklist",
                    "id": "cryotherapy_treatment",
                    "items": [
                        {
                            "value": "page.cryotherapy.treatment.c1",
                            "key": "ujWmVV6aTH2WRCg+MK81qA=="
                        },
                        {
                            "value": "page.cryotherapy.treatment.c2",
                            "key": "6mE2l+CpSQaaefBU7rkBbw=="
                        },
                        {
                            "value": "page.cryotherapy.treatment.c3",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        },
                        {
                            "value": "page.cryotherapy.treatment.c4",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        },
                        {
                            "value": "page.cryotherapy.treatment.c5",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        },
                        {
                            "value": "page.cryotherapy.treatment.c6",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        }
                    ],
                    "key": "Ab5k8/poTSWDSEnPEKoWXw=="
                }
            ],
            "key": "treatment-panel"
        },
        {
            "type": "panel",
            "title": "page.cryotherapy.benefits.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "cryotherapy_benefits",
                    "items": [
                        {
                            "value": "page.cryotherapy.benefits.b1",
                            "key": "ujWmVV6aTH2WRCg+MK81qA=="
                        },
                        {
                            "value": "page.cryotherapy.benefits.b2",
                            "key": "6mE2l+CpSQaaefBU7rkBbw=="
                        },
                        {
                            "value": "page.cryotherapy.benefits.b3",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        },
                        {
                            "value": "page.cryotherapy.benefits.b4",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        }
                    ],
                    "key": "Ab5k8/poTSWDSEnPEKoWXw=="
                }
            ],
            "key": "benefits-panel"
        },
        {
            "type": "panel",
            "title": "page.cryotherapy.cost.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.cryotherapy.cost.p1",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "table",
                    "columnWidth": "col-3",
                    "columns": [
                        "",
                        "page.cryotherapy.cost.table.header.c2",
                        "page.cryotherapy.cost.table.header.c3",
                        "page.cryotherapy.cost.table.header.c4"
                    ],
                    "source": "file",
                    "data": "cryotherapy",
                    "headingStyle": {
                        "fontWeight": "bold",
                        "textAlign": "center",
                        "paddingTop": 30,
                        "fontSize": "1.5em",
                        "key": "yRDxjpw9TNyf3ysIeOjFrw=="
                    },
                    "cellStyle": {
                        "paddingTop": 15,
                        "paddingLeft": 15,
                        "key": "tiMfu+EgQhWUGFZFkzEkxg=="
                    },
                    "cellStyleNoLeftPadding": {
                        "paddingTop": 15,
                        "key": "V+3/imKDT4uTkvi0EEOAwA=="
                    },
                    "key": "HNzczy15SkWwqow6rwZf2g=="
                }
            ],
            "key": "cost-panel"
        },
        {
            "type": "panel",
            "title": "page.cryotherapy.booking.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.cryotherapy.booking.p1",
                    "markdown": true,
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                }
            ],
            "key": "booking-panel"
        },
        {
            "type": "panel",
            "title": "page.cryotherapy.faq.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.q1",
                    "bold": true,
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.a1",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.q2",
                    "bold": true,
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.a2",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.q3",
                    "bold": true,
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.a3",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.q4",
                    "bold": true,
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.cryotherapy.faq.a4",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                }
            ],
            "key": "faq-panel"
        }
    ],
    "key": "oDU0Y369QOuFVByfBwZaWA=="
}