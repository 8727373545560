{
    "data": [
        {
            "treatment": "Small Lesions",
            "single": "\u00a350.00",
            "up_to_3": "\u00a365.00",
            "additional": "\u00a310.00",
            "key": "cMIzHnCUQWat6t4uAYw+IA=="
        },
        {
            "treatment": "Medium Lesions",
            "single": "\u00a360.00",
            "up_to_3": "\u00a380.00",
            "additional": "\u00a315.00",
            "key": "yF36vieBSte1UYETLfxYFQ=="
        },
        {
            "vaccination": "Large Lesions",
            "single": "\u00a370.00",
            "key": "FiCs5Y64SjOqosXQ/frDaw=="
        },
        {
            "treatment": "Verruca/ wart initial",
            "single": "\u00a380.00",
            "key": "qn7ygznxQgegmrgqo5Ankw=="
        },
        {
            "vaccination": "Verruca/Wart follow-up",
            "single": "\u00a340.00",
            "key": "0EofcgGCSwectQP3c00Erw=="
        }
    ],
    "key": "1cAUeoF/TqmsEN74M6WOaA=="
}