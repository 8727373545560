{
    "title": "page.ordering.title",
    "components": [
        {
            "type": "panel",
            "title": "page.ordering.repeats.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "ordering_repeats",
                    "items": [
                        {
                            "value": "page.ordering.repeats.c1",
                            "key": "SI4dG8FyTlytGvkARZ2bvw=="
                        },
                        {
                            "value": "page.ordering.repeats.c2",
                            "key": "UeDOHkddQQeM5vt9I/GwCw=="
                        }
                    ],
                    "key": "Py7Zi6ucTc+smnZa426Snw=="
                },
                {
                    "type": "imageLink",
                    "image": "google-play-badge.png",
                    "url": "https://play.google.com/store/apps/details?id=app.remedi.repeats",
                    "height": 58,
                    "key": "xzeCScKyTHS0q8PR2OYqnA=="
                },
                {
                    "type": "imageLink",
                    "image": "app-store-badge.svg",
                    "url": "https://apps.apple.com/gb/app/id1502236015",
                    "style": {
                        "paddingBottom": "9px",
                        "key": "TSNKWyhPQt64T8q3/cIJow=="
                    },
                    "key": "Or9bk3dvQYiWrpN8c51Cbg=="
                }
            ],
            "key": "BhDvvzBsTeyaGPjiWbELKw=="
        },
        {
            "type": "panel",
            "title": "page.ordering.phone.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "ordering_phone",
                    "items": [
                        {
                            "value": "page.ordering.phone.c1",
                            "key": "1l9meAULS7SJAquJ5Mu3sQ=="
                        },
                        {
                            "value": "page.ordering.phone.c2",
                            "key": "GmMo7Xf/RC+CmGPIuMztIw=="
                        }
                    ],
                    "key": "O4K+2g/YSo6ZgL/Et1VvOg=="
                }
            ],
            "key": "glH1YHRsSwKqO9okiFFwBQ=="
        },
        {
            "type": "panel",
            "title": "page.ordering.pharmacy.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "ordering_pharmacy",
                    "items": [
                        {
                            "value": "page.ordering.pharmacy.c1",
                            "key": "/z2mkaNHT8Wp8pzhycvraQ=="
                        }
                    ],
                    "key": "2eZxCTyJQCuD0vtG7cFqKg=="
                },
                {
                    "type": "button",
                    "id": "locate-store-button",
                    "title": "page.ordering.pharmacy.locatestore",
                    "url": "/FindUs",
                    "indent": true,
                    "key": "FvdWrMypQjGsv5SC+ZQlwg=="
                }
            ],
            "key": "7PZEN6NORBG4klnrM0k/cw=="
        }
    ],
    "key": "p1fomgeBRNuCrajE2QGg5w=="
}